<template>
    <div>
        <v-btn
            v-if="leftIcon != null || rightIcon != null || label != null"
            @click="showPanel = !showPanel"
            :block="block"
            :class="buttonClass"
            :icon="(leftIcon != null || rightIcon != null) && label == null"
            :small="small">
            <v-icon v-if="leftIcon != null" :left="label != null" :small="small">{{ leftIcon }}</v-icon>
                {{ label }}
            <v-icon v-if="rightIcon != null" :right="label != null" :small="small">{{ rightIcon }}</v-icon>
        </v-btn>

        <v-navigation-drawer
            v-model="showPanel"
            app
            :bottom="bottom"
            :clipped="clipped"
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click="showPanel = false">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ sidebarLabel || label }}</v-toolbar-title>
                <v-spacer />
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>
            
            <v-list-item v-if="asyncExternalParty != null && stage == null">
                <v-list-item-avatar>
                    <v-img :src="imageURL(asyncExternalParty.party + '.png')" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-card-title>{{ capitalizeWords(asyncExternalParty.party) }}</v-card-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="asyncExternalParty != null && stage == null" class="my-2" />
            
            <v-alert v-model="errorMsg" dismissible type="error">{{ errorMsg }}</v-alert>

            <div v-if="!isLengthyArray(asyncExternalParties)">
                <BT-Btn
                    bladeName="external-party-options"
                    block
                    leftIcon="mdi-connection"
                    label="Connect"
                    title="Connect to a third party" />
            </div>
            <BT-List-Endless v-else-if="asyncExternalParty == null"
                :canSearch="false"
                :items="asyncExternalParties"
                itemValue="party"
                showList>
                <template v-slot:listItem="{ item }">
                    <v-card @click="selectExternalParty(item)">
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-img :src="imageURL(item.party + '.png')" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-card-title>{{ capitalizeWords(item.party) }}</v-card-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </template>
            </BT-List-Endless>
            <v-list v-else-if="asyncItems == null">
                <v-list-item v-if="externalNavigation != null && externalNavigation.canPush == true" two-line @click="selectUnsynced" :ripple="false">
                    <v-list-item-action>{{ unsyncedCount | toDisplayNumber }}</v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Unsynced</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon small>mdi-arrow-right</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="externalNavigation != null && externalNavigation.canPush == true" two-line @click="selectSynced" :ripple="false">
                    <v-list-item-action>{{ syncedCount | toDisplayNumber }}</v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Synced</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon small>mdi-arrow-right</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="externalNavigation != null && externalNavigation.canPull == true" two-lin @click="selectSearch" :ripple="false">
                    <v-list-item-action>
                        <v-icon small>mdi-magnify</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Search {{ capitalizeWords(asyncExternalParty.party) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <BT-List-Endless
                v-else-if="stage == 'search'"
                :canSearch="false"
                canSearchLocal
                height="auto"
                :items="asyncItems"
                :onFilter="filterSyncItems"
                :searchProps="['external.' + externalDisplayPath, 'local.' + localDisplayPath]">
                <template v-slot:toolbar-below>
                    <v-toolbar dense class="ma-0 pa-0">
                        <BT-Menu text="Select" hideHeader isButton :icon="null" closeOnClick>
                            <template v-slot>
                                <v-list-item @click="syncSelectNone">
                                    <v-list-item-content>
                                        <v-list-item-title>Deselect All</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider />
                                <v-list-item @click="syncSelectAll">
                                    <v-list-item-content>
                                        <v-list-item-title>All</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="syncSelectSuggestions">
                                    <v-list-item-content>
                                        <v-list-item-title>Suggestions</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="syncSelectPush">
                                    <v-list-item-content>
                                        <v-list-item-title>All To Push</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="syncSelectPull">
                                    <v-list-item-content>
                                        <v-list-item-title>All To Pull</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Menu>
                        <v-spacer />
                        <v-btn 
                            v-if="isLengthyArray(asyncItems.filter(z => z.isSelected))"
                            small 
                            @click="pushSyncItems">sync ({{ asyncItems.filter(z => z.isSelected).length | toDisplayNumber }})</v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:title-left>
                    <v-avatar><v-img :src="imageURL(asyncExternalParty.party + '.png')" /></v-avatar>
                    <v-toolbar-title>{{ capitalizeWords(asyncExternalParty.party) }}</v-toolbar-title>
                </template>
                <template v-slot:listItem="{ item }">
                    <v-list-item three-line :ripple="false">
                        <v-list-item-action class="d-flex align-self-center">
                            <v-icon v-if="item.message != null" color="warning" :title="item.message">mdi-alert-circle</v-icon>
                            <v-btn v-else icon @click="item.isSelected = !item.isSelected" :disabled="item.isSynced">
                                <v-icon v-if="item.isSynced" class="success--text">mdi-check</v-icon>
                                <v-icon 
                                    v-else-if="item.external != null && item.local != null"
                                    :class="item.isSelected ? 'primary--text' : null">mdi-sync</v-icon>
                                <v-icon 
                                    v-else-if="item.external != null"
                                    :class="item.isSelected ? 'primary--text' : null">mdi-cloud-download</v-icon>
                                <v-icon 
                                    v-else-if="item.local != null"
                                    :class="item.isSelected ? 'primary--text' : null">mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-list-item-action>

                        <v-list-item-content>
                            <v-list-item-title>
                                <v-avatar size="36"><v-img :src="imageURL(asyncExternalParty.party + '.png')" /></v-avatar>
                                <span v-if="item.external != null">{{ nestVal(item.external, externalDisplayPath) }}</span>
                                <v-btn v-if="item.external != null && !item.isSynced && item.local != null" icon small title="Deselect" @click="syncDeselect(item)">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                                <v-btn v-else-if="item.external == null" icon small title="Search" @click="searchExternal(item)">
                                    <v-icon small>mdi-magnify</v-icon>
                                </v-btn>
                            </v-list-item-title>
                            <v-divider class="my-1" />
                            <v-list-item-title>
                                <v-avatar size="20" class="mx-2"><v-img eager src="/img/logo-72x72.png"/></v-avatar>
                                <span v-if="item.local != null">{{ nestVal(item.local, localDisplayPath) }}</span>
                                <v-btn v-if="item.local != null && !item.isSynced && item.external != null" icon small title="Deselect" @click="syncDeselect(item)">
                                    <v-icon small>mdi-close</v-icon>
                                </v-btn>
                                <v-btn v-else-if="item.local == null" icon small title="Search" @click="searchLocal(item)">
                                    <v-icon small>mdi-magnify</v-icon>
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </BT-List-Endless>
            <BT-List-Endless
                v-else
                :canSearch="false"
                canSearchLocal
                height="auto"
                :items="asyncItems"
                :ripple="false"
                :searchProps="[localDisplayPath, localComparePath]">
                <template v-slot:toolbar-below>
                    <v-toolbar v-if="stage != 'synced'" dense class="ma-0 pa-0">
                        <v-btn 
                            small 
                            @click="toggleUnsyncedSelection">{{ asyncItems.some(z => !z.isSynced && !z.isSelected) ? 'Select All' : 'Unselect All' }}</v-btn>
                        <v-spacer />
                        <v-btn 
                            v-if="stage == 'unsynced' && isLengthyArray(asyncItems.filter(z => z.isSelected))"
                            small 
                            @click="pushUnsynced">sync ({{ asyncItems.filter(z => z.isSelected).length | toDisplayNumber }})</v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:title-left>
                    <v-avatar><v-img :src="imageURL(asyncExternalParty.party + '.png')" /></v-avatar>
                    <v-toolbar-title>{{ capitalizeWords(asyncExternalParty.party) }}</v-toolbar-title>
                </template>
                <template v-slot="{ item }">
                    <v-list-item-action>
                        <!-- <span v-if="item[asyncExternalParty.property] != null">
                            <v-icon small class="success--text">mdi-check</v-icon>
                        </span> -->
                        <span v-if="containsReference(item)">
                            <v-icon small class="success--text">mdi-check</v-icon>
                        </span>
                        <v-btn v-else small @click.stop="item.isSelected = !item.isSelected" icon :title="'Push to ' + asyncExternalParty.party">
                            <v-icon small :class="item.isSelected ? 'primary--text' : null">mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <slot name="listItem" v-bind:item="item" />
                </template>
            </BT-List-Endless>
         
            <BT-Select-Dialog
                canSearch
                :items="selectableItems"
                label="Options"
                @change="selectOption"
                :showToggle="showOptions"
                hideButton
                returnObject
                :searchProps="['local.' + localDisplayPath, 'external.' + externalDisplayPath]"
                width="450">
                <template v-slot="{ item }">
                    <v-list-item-content>
                        <v-list-item-title v-if="selectingLocal">{{ nestVal(item.local, localDisplayPath) }}</v-list-item-title>
                        <v-list-item-title v-else>{{ nestVal(item.external, externalDisplayPath) }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </BT-Select-Dialog>

            <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { compareString, nestedValue, toCamelCase } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'BT-Sidebar-External-Integration',
    components: {
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue')
    },
    data: function() {
        return {
            asyncItem: null,
            asyncItems: null,
            asyncExternalParties: [],
            asyncExternalParty: null,
            errorMsg: null,
            externalItems: [],
            loadingMsg: null,
            localItems: [],
            showOptions: false,
            showPanel: false,
            stage: null,
            unsyncedCount: 0,
            selectableItems: [],
            selectedSyncItem: null,
            selectedItems: [],
            selectingLocal: false,
            syncedCount: 0
        }
    },
    mounted() {
        this.showPanel = this.startOpen;
    },
    props: {
        block: {
            type: Boolean,
            default: true
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: null
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        clipped: {
            type: Boolean,
            default: true
        },
        dividers: {
            type: Boolean,
            default: true
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        itemID: {
            type: String,
            default: null
        },
        itemProperties: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        leftIcon: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '350'
        },
        onCompare: {
            type: Function,
            default: function (localItem, externalItem) {
                return compareString(nestedValue(localItem, this.localComparePath)) == compareString(nestedValue(externalItem, this.externalComparePath));
            }
        },
        onPullNewItems: {
            type: Function, //function([{ externalItemID, externalItem }])
            default: null
        },
        rightIcon: {
            type: String,
            default: null
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        sidebarLabel: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        startOpen: {
            type: Boolean,
            default: false
        },
        syncNavigation: {
            type: String,
            default: null
        }
    },
    watch: {
        showPanel: function(val) {
            if (val) {
                this.pullItems();
            }
        },
        showToggle: function() {
            this.showPanel = !this.showPanel;
        }
    },
    computed: {
        externalNavigation() {
            if (this.asyncExternalParty == null) {
                return null;
            }

            return this.$BlitzIt.navigation.findItem(this.syncNavigation).externalNavigations.find(x => x.name === this.asyncExternalParty.party);
        },
        externalDisplayPath() {
            return this.externalNavigation == null ? null : this.externalNavigation.syncDisplayPath;
        },
        externalComparePath() {
            return this.externalNavigation == null ? null : this.externalNavigation.syncComparePath;
        },
        externalIDPath() {
            return this.externalNavigation == null ? null : this.externalNavigation.syncIDPath;
        },
        localDisplayPath() {
            return this.externalNavigation == null ? null : this.externalNavigation.localDisplayPath;
        },
        localComparePath() {
            return this.externalNavigation == null ? null : this.externalNavigation.localComparePath;
        },
        possibleExternalParties() {
            return this.$BlitzIt.navigation.findExternalParties(this.syncNavigation);
        },
    },
    methods: {
        containsReference(item) {
            if (this.asyncExternalParty == null) {
                return false;
            }

            var erProp = this.asyncExternalParty.property || 'externalReferences';

            var l = item[erProp];

            var r = false;
            if (Array.isArray(l)) {
                r = l.find(x => x.externalParty == this.asyncExternalParty.party) != null;
            }
            else {
                r = l[erProp] != null;
            }
            
            return r;
        },
        createReference(item, externalID) {
            if (this.asyncExternalParty == null) {
                return false;
            }

            var erProp = this.asyncExternalParty.property || 'externalReferences';

            var l = item[erProp];

            if (Array.isArray(l)) {
                l.push({
                    externalParty: this.asyncExternalParty.party,
                    externalID: externalID
                });
            }
            else {
                return l[erProp] = externalID;
            }
        },
        getExternalID(item) {
            if (this.asyncExternalParty == null) {
                return false;
            }

            var erProp = this.asyncExternalParty.property || 'externalReferences';

            var l = item[erProp];
            
            if (Array.isArray(l)) {
                var e = l.find(x => x.externalParty == this.asyncExternalParty.party);

                if (e != null) {
                    return e.externalID;
                }
                else {
                    return null;
                }
            }
            else {
                return l[erProp];
            }
        },
        getItemProperties() {
            var props = [];
            
            if (this.isLengthyArray(this.itemProperties)) {
                this.itemProperties.forEach(prop => {
                    props.push(this.capitalizeWords(prop));
                })
            }

            if (this.localDisplayPath != null) {
                props.push(this.capitalizeWords(this.localDisplayPath.split('.')[0]));
            }

            if (this.localComparePath != null) {
                props.push(this.capitalizeWords(this.localComparePath.split('.')[0]));
            }

            props.push('ID');

            props.push(this.capitalizeWords(this.asyncExternalParty.property || 'externalReferences'));

            return [...new Set(props)];
        },
        nestVal(item, path) {
            return nestedValue(item, path);
        },
        filterSyncItems(items) {
            items.sort(firstBy(x => x.external != null ? this.nestVal(x.external, this.externalDisplayPath) : this.nestVal(x.local, this.localDisplayPath), { ignoreCase: true }));
            return items;
        },
        async pullItems(refresh = false) {
            this.asyncExternalParties = [];

            try {
                this.loadingMsg = 'Searching For External Parties';
                
                if (this.isLengthyArray(this.possibleExternalParties)) {
                    var res = await this.$BlitzIt.store.getAll('external-party-credentials', { filterBy: 'Connected' }, refresh, null, null);
                    if (this.isLengthyArray(res)) {
                        res.forEach(element => {
                            var cred = this.possibleExternalParties.find(x => x.party == element.externalPartyOptionID);
                            if (cred != null) {
                                this.asyncExternalParties.push(cred);
                            }
                        });
                    }
                }
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pushToParty(extItem) {
            try {
                this.loadingMsg = 'Pushing';
                var listRes = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, extItem.id, this.asyncExternalParty.party);
                var returnItem = Array.isArray(listRes) ? listRes[0] : listRes;
                
                if (returnItem.message != null) {
                    this.errorMsg = returnItem.message;
                }
                else {
                    this.createReference(extItem, returnItem.externalItemID);
                    // extItem[this.asyncExternalParty.property] = returnItem.externalItemID;
                }
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pushSyncItems() {
            try {
                this.loadingMsg = 'Syncing to ' + this.fromCamelCase(this.asyncExternalParty.party);

                // var rItems = this.asyncItems.filter(z => !z.isSynced && z.isSelected && this.nestVal(z, this.asyncExternalParty.property) == null);
                var rItems = [];
                var newItems = [];

                this.asyncItems.forEach(x => {
                    if (!x.isSynced && x.isSelected) {
                        if (x.local == null || !this.containsReference(x.local)) {
                            if (x.external != null && x.local == null && this.onPullNewItems != null) {
                                newItems.push({ 
                                    //externalID: this.nestVal(x, this.nestVal(x, this.externalIDPath)).toString(), 
                                    externalItemID: x.external != null ? this.nestVal(x.external, this.externalIDPath).toString() : null,
                                    externalItem: x.external });
                            }
                            else {
                                rItems.push({
                                    localID: x.local != null ? x.local.id : null,
                                    externalItemID: x.external != null ? this.nestVal(x.external, this.externalIDPath).toString() : null
                                });
                            }
                        }
                    }
                })

                if (this.isLengthyArray(rItems)) {
                    var r = await this.$BlitzIt.api.patch(this.syncNavigation, rItems, null, '/patch?partyID=' + this.asyncExternalParty.party);
                    r.data.data.forEach(rItem => {
                        var aInd = this.asyncItems.findIndex(z => (rItem.externalItemID != null && rItem.externalItemID == this.nestVal(z.external, this.externalIDPath)) || (rItem.localID != null && rItem.localID == this.nestVal(z.local, 'id')));
                        if (aInd >= 0) {
                            var aItem = this.asyncItems[aInd];
                            if (rItem.message != null) {
                                aItem.message = rItem.message;
                            }
                            else {
                                //remove
                                this.asyncItems.splice(aInd, 1);
                            }
                        }
                    })
                }

                if (this.isLengthyArray(newItems)) {
                    this.$BlitzIt.store.clear(this.navigation);
                    this.$BlitzIt.store.clear(this.syncNavigation);
                    this.onPullNewItems(newItems, this.asyncExternalParty.party);
                }

            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pushUnsynced() {
            try {
                this.loadingMsg = 'Pushing to ' + this.fromCamelCase(this.asyncExternalParty.party);

                var rItems = this.asyncItems.filter(z => z.isSelected && !this.containsReference(z));

                rItems = rItems.map(x => { return {
                    localID: x.id,
                    externalItemID: null
                }});

                var r = await this.$BlitzIt.api.patch(this.syncNavigation, rItems, null, '/patch?partyID=' + this.asyncExternalParty.party);

                r.data.data.forEach(rItem => {
                    if (rItem.externalItemID != null) {
                        var ind = this.asyncItems.findIndex(z => z.id == rItem.localID);
                        if (ind > -1) {
                            this.asyncItems.splice(ind, 1);
                        }
                    }
                })
            }
            catch (err) {
                this.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        refresh(refresh = true) {
            this.asyncExternalParty = null;
            this.stage = null;
            this.asyncItems = null;
            this.errorMsg = null;
            this.pullItems(refresh);
        },
        searchLocal(syncItem) {
            if (syncItem.isSynced) {
                return;
            }

            this.selectableItems = this.asyncItems.filter(z => !z.isSynced && z.local != null);
            this.showOptions = !this.showOptions;
            this.selectedSyncItem = syncItem;
            this.selectingLocal = true;
        },
        searchExternal(syncItem) {
            if (syncItem.isSynced) {
                return;
            }

            this.selectableItems = this.asyncItems.filter(x => !x.isSynced && x.external != null);
            this.showOptions = !this.showOptions;
            this.selectedSyncItem = syncItem;
            this.selectingLocal = false;
        },
        async selectExternalParty(party) {
            this.asyncExternalParty = party;

            if (party != null) {
                try {
                    this.loadingMsg = 'Loading';
                    // var uRes = await this.$BlitzIt.api.getCount(this.navigation, { query: this.capitalizeWords(party.property) + '=null' });
                    // var sRes = await this.$BlitzIt.api.getCount(this.navigation, { query: this.capitalizeWords(party.property) + '!=null' });
                    this.unsyncedCount = 0; //uRes.data.data;
                    this.syncedCount = 0; //sRes.data.data;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        selectOption(syncItem) {
            if (this.selectedSyncItem == null) {
                return;
            }

            if (this.selectingLocal) {
                this.selectedSyncItem.local = syncItem.local;
                syncItem.local = null;
                syncItem.isSuggestion = false;
            }
            else {
                this.selectedSyncItem.external = syncItem.external;
                syncItem.external = null;
                syncItem.isSuggestion = false;
            }

            var sInd = this.asyncItems.findIndex(x => x === syncItem);
            if (sInd >= 0) {
                this.asyncItems.splice(sInd, 1);
            }
        },
        async selectSynced() {
            try {
                this.loadingMsg = 'Loading';
                this.stage = 'synced';
                
                var res = await this.$BlitzIt.store.getAll(this.navigation, { properties: this.getItemProperties().toString(), externalPartySynced: this.asyncExternalParty.party }, true);
                res = res.filter(x => this.containsReference(x));
                this.asyncItems = res.map(x => { return Object.assign({}, x, { isSelected: false })});
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectUnsynced() {
            try {
                this.loadingMsg = 'Loading';
                this.stage = 'unsynced';
                var res = await this.$BlitzIt.store.getAll(this.navigation, { properties: this.getItemProperties().toString(), externalPartyUnsynced: this.asyncExternalParty.party}, true);
                res = res.filter(x => !this.containsReference(x));
                this.asyncItems = res.map(x => { return Object.assign({}, x, { isSelected: false })});
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectSearch() {
            try {
                this.loadingMsg = 'Loading';
                this.stage = 'search';
                var res = await this.$BlitzIt.store.getAll(this.navigation, { properties: this.getItemProperties().toString() }, true);
                this.localItems = res; //.filter(x => !this.containsReference(x));
                var syncRes = await this.$BlitzIt.api.getAll(this.syncNavigation, { partyID: this.asyncExternalParty.party, onlyUnsynced: true });
                this.externalItems = JSON.parse(syncRes.data.data, toCamelCase);
                this.asyncItems = this.loadEverything(this.externalItems, this.localItems);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectAllUnsynced() {
            this.asyncItems.forEach(x => {
                if (!x.isSynced) {
                    x.isSelected = true;
                }
            });
        },
        toggleUnsyncedSelection() {
            var isSel = this.asyncItems.some(z => !z.isSynced && !z.isSelected);
            this.asyncItems.forEach(z => {
                z.isSelected = isSel;
            });
        },
        syncDeselect(item) {
            if (!item.isSynced && item.external != null && item.local != null) {
                this.asyncItems.push({
                    external: item.external,
                    local: null,
                    isSelected: false,
                    isSuggestion: false,
                    isSynced: false,
                    message: null
                });

                item.external = null;
            }
        },
        syncSelectAll() {
            this.asyncItems.forEach(x => {
                x.isSelected = !x.isSynced;
            })
        },
        syncSelectNone() {
            this.asyncItems.forEach(x => {
                x.isSelected = false;
            })
        },
        syncSelectSuggestions() {
            this.asyncItems.forEach(x => {
                x.isSelected = !x.isSynced && x.isSuggestion;
            })
        },
        syncSelectPush() {
            this.asyncItems.forEach(x => {
                x.isSelected = !x.isSynced && x.external == null && x.local != null;
            })
        },
        syncSelectPull() {
            this.asyncItems.forEach(x => {
                x.isSelected = !x.isSynced && x.external != null && x.local == null;
            })
        },
        //status options: createLocalItem, createExternalItem, matched, unknown, suggestion
        loadEverything(externalItems, localItems) {
            if (externalItems == null || localItems == null) {
                return null;
            }

            var sItems = externalItems.map(x => { return Object.assign({}, { 
                external: x,
                local: null,
                isSelected: false,
                isSuggestion: false,
                isSynced: false,
                message: null })
            });

            localItems.forEach(lItem => {
                var extID = this.getExternalID(lItem);
                // var extID = lItem[this.asyncExternalParty.property];

                if (extID != null) {
                    var extItem = sItems.find(z => z.external != null && z.external[this.externalIDPath] == extID);
                    if (extItem != null) {
                        extItem.local = lItem;
                        extItem.isSynced = true;
                    }
                    else {
                        var comItem = sItems.find(z => this.onCompare(lItem, z.external));
                        if (comItem != null) {
                            comItem.local = lItem;
                            comItem.isSuggestion = true;
                        }
                    }
                }
                else {
                    var mItem = sItems.find(z => this.onCompare(lItem, z.external));
                    if (mItem != null) {
                        mItem.local = lItem;
                        mItem.isSuggestion = true;
                    }
                    else {
                        sItems.push({
                            external: null,
                            local: lItem,
                            isSelected: false,
                            isSuggestion: false,
                            isSynced: false,
                            message: null
                        })
                    }
                }
            })

            // sItems.sort(firstBy(x => x.external != null ? x.external[this.externalDisplayPath] : x.local[this.localDisplayPath], { ignoreCase: true }));

            return sItems;
        },
    }
}
</script>